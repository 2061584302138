import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/scroll-to-top";

const HomePage = React.lazy(() => import("../pages/home"));
const ContactPage = React.lazy(() => import("../pages/contact"));
const AboutUsPage = React.lazy(() => import("../pages/about-us"));
const CareerPage = React.lazy(() => import("../pages/career"));
const TermsConditionsPage = React.lazy(
  () => import("../pages/terms-conditions")
);
const Loader = React.lazy(() => import("../components/loader"));
const PrivacyPolicyPage = React.lazy(() => import("../pages/privacy-policy"));
const DocumentPage = React.lazy(() => import("../pages/documents"));
const NotFoundPage = React.lazy(() => import("../pages/not-found"));
const AllRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      {/* <BrowserRouter> */}
      <ScrollToTop />
      <Routes>
        <Route element={<HomePage />} path="/"></Route>
        <Route element={<AboutUsPage />} path="/about-us"></Route>
        <Route element={<ContactPage />} path="/contact"></Route>
        <Route element={<CareerPage />} path="/career"></Route>
        <Route
          element={<TermsConditionsPage />}
          path="/terms-conditions"
        ></Route>
        <Route element={<PrivacyPolicyPage />} path="/privacy-policy"></Route>
        <Route element={<DocumentPage />} path="/documents"></Route>

        <Route element={<NotFoundPage />} path="*"></Route>
      </Routes>
      {/* </BrowserRouter> */}
    </Suspense>
  );
};

export default AllRoutes;
