import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import "react-responsive-modal/styles.css";
import "../src/assets/css/main.scss";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
